<template>
  <v-layout>
    <v-container fluid>
      <v-layout wrap>
        <v-flex md9 class="pa-3 pb-6">
          <v-data-table
            :headers="headers"
            :items="reviews.data.reviews.data"
            item-class="primary"
            :footer-props="null"
            hide-default-footer
          >
            <template v-slot:top>
              <v-card-title class="mb-8">
                <h3>
                  <v-icon class="mr-2" color="tertiary">business_center</v-icon>
                  {{ location.data.locationName }}
                </h3>

                <v-spacer />

                <v-btn-toggle>
                  <v-btn small to="/locations/add">
                    <v-icon small class="mr-1">add_location</v-icon>
                    Add New Location
                  </v-btn>

                  <v-btn small to="/locations/list">
                    <v-icon small class="mr-1">gps_fixed</v-icon>
                    All Locations
                  </v-btn>
                </v-btn-toggle>
              </v-card-title>

              <v-card-subtitle>
                <v-alert type="info" tile color="info darken-2" class="text-caption">
                  <v-btn color="white" light class="float-right" tile @click="reCrawlReviews()">
                    <v-progress-circular indeterminate color="primary" size="22" v-if="loading" class="mr-2"></v-progress-circular>
                    <v-icon class="mr-2" v-else>autorenew</v-icon>
                    Fetch Latest Reviews
                  </v-btn>
                  Reviews last pulled: {{ location.data.data.reviewLastUpdated }}<br>
                  Latest # of reviews pulled: {{ location.data.data.reviewLastTotalUpdate }}<br>
                </v-alert>
              </v-card-subtitle>
            </template>

            <template v-slot:no-data>
                <tr>
                  <td>No Reviews yet.</td>
                </tr>
            </template>

            <template v-slot:item.authorName="{ item }">
              <v-avatar class="mr-2">
                <v-img
                  width="50"
                  height="50"
                  :src="item.profilePhotoUrl"
                />
              </v-avatar>
              {{ item.authorName }}
            </template>

            <template v-slot:item.review="{ item }">
              <p class="mt-3 mb-3">{{ item.review }}</p>
            </template>

            <template v-slot:item.timestamp="{ item }">
              {{ item.timestamp.substring(0, 10) }}
            </template>

            <template v-slot:item.rating="{ item }">
              <span v-for="n in parseInt(item.rating)" :key="n">
                <v-icon small color="secondary">star</v-icon>
              </span>
            </template>

            <template v-slot:item.rating="{ item }">
              <span v-for="n in parseInt(item.rating)" :key="n">
                <v-icon small color="secondary">star</v-icon>
              </span>
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn color="red" light class="float-right" tile @click="deletereview(item.id)">
                <v-progress-circular indeterminate color="primary" size="22" v-if="loading" class="mr-2"></v-progress-circular>
                <v-icon class="mr-2" v-else>delete</v-icon>
                  Delete
              </v-btn>
            </template>

            <template v-slot:footer>
              <v-divider />
              <v-layout id="locationReviewPagination" class="justify-center">
                <v-pagination
                  v-model="reviews.data.reviews.current_page"
                  :length="reviews.data.reviews.last_page"
                  total-visible="10"
                  color="tertiary"
                  @input="reviewsPaginate({ id: location.data.gafId, page: reviews.data.reviews.current_page, pagesize: 10, order: 'rating' })"
                  class="mt-8 mb-8"
                />
              </v-layout>
            </template>

          </v-data-table>
        </v-flex>

        <v-flex md3 class="pa-3">
          <v-form @submit.prevent="editLocation" ref="form">
            <v-card elevation="0">
              <v-card-title class="mb-4">
                <h3>
                  <v-icon class="mr-2" color="tertiary">edit_location</v-icon>
                  BASIC INFORMATION
                </h3>
              </v-card-title>

              <v-card-subtitle class="text-right">
                <h6>last updated: {{ location.data.updated_at }}</h6>
              </v-card-subtitle>

              <v-select
                class="pa-3 pl-5 pr-5 text-uppercase"
                :items="statusItems"
                prefix="Status: "
                solo
                v-model="$v.location.data.status.$model"
              />

              <v-card-text class="pa-3 pl-5 pr-5">
                <v-text-field
                  label="Location Name"
                  v-model="$v.location.data.locationName.$model"
                  :error="$v.location.data.locationName.$anyError"
                  :persistent-hint="$v.location.data.locationName.$invalid"
                  :error-messages="$v.location.data.locationName.$anyError ? 'Location Name is required' : ''"
                  @blur="$v.location.data.locationName.$touch()"
                />

                <v-layout wrap>
                  <v-flex xs12 class="pr-1">
                    <v-text-field
                      v-model="$v.location.data.gafId.$model"
                      :error="$v.location.data.gafId.$anyError"
                      :persistent-hint="$v.location.data.gafId.$invalid"
                      :error-messages="$v.location.data.gafId.$anyError ? 'GAF ID is required' : ''"
                      @blur="$v.location.data.gafId.$touch()"
                      label="GAF ID"
                    />
                  </v-flex>
                  <v-flex xs12 class="pl-1">
                    <v-text-field
                      v-model="$v.location.data.gmbId.$model"
                      label="GMB ID"
                      placeholder="null"
                    />
                  </v-flex>
                </v-layout>

                <v-text-field
                  v-model="$v.location.data.address1.$model"
                  :error="$v.location.data.address1.$anyError"
                  :persistent-hint="$v.location.data.address1.$invalid"
                  :error-messages="$v.location.data.address1.$anyError ? 'Address is required' : ''"
                  @blur="$v.location.data.address1.$touch()"
                  label="Address Line 1"
                />
                <v-text-field
                  v-model="$v.location.data.address2.$model"
                  label="Address Line 2"
                />

                <v-layout wrap>
                  <v-flex xs6 class="pr-1">
                    <v-text-field
                      v-model="location.data.city"
                      label="City"
                    />
                  </v-flex>
                  <v-flex xs6 class="pl-1">
                    <v-text-field
                      v-model="location.data.state"
                      label="State"
                    />
                  </v-flex>
                  <v-flex xs6 class="pr-1">
                    <v-text-field
                      v-model="location.data.zip"
                      label="ZIP"
                    />
                  </v-flex>
                  <v-flex xs6 class="pl-1">
                    <v-text-field
                      v-model="location.data.country"
                      label="Country"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-alert v-if="this.validationAlert.status" :type="this.validationAlert.type" :value="this.validationAlert.status" class="block">
                    {{ this.validationAlert.message }}
                </v-alert>
                <v-spacer></v-spacer>
              </v-card-actions>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="submit" color="tertiary" dark class="pa-5">
                  <v-icon class="mr-2">save</v-icon>
                  Update Location Info
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>

    </v-container>
  </v-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  name: 'LocationsEdit',

  data () {
    return {
      validationAlert: {
        status: false,
        type: null,
        message: null
      },
      headers: [
        { text: 'Name', value: 'authorName', width: '20%' },
        { text: 'Review', value: 'review', width: '40%' },
        { text: 'Review Date', value: 'timestamp', width: '15%' },
        { text: 'Rating', value: 'rating', width: '15%' },
        { text: 'Action', value: 'id', width: '10%' },
      ],
      statusItems: [ 'active', 'new', 'temporarily closed', 'permanently closed', 'not found']
    }
  },

  computed: mapState([
    'alert',
    'location',
    'loading',
    'reviews'
  ]),

  validations: {
    location: {
      data: {
        locationName: { required },
        gafId: { required },
        gmbId: false,
        address1: { required },
        address2: false,
        city: { required },
        state: { required },
        zip: { required },
        country: { required },
        status: false
      }
    }
  },

  watch: {
    location: {
      handler: function (newData) {
        if (!newData) return
        this.alert.status = false
        this.validationAlert.status = false
      },
      deep: true
    }

  },

  beforeRouteLeave  (to, from, next) {
    next()
    if (this.$route.params.locationID) {
      this.$store.dispatch('loadLocation', this.$route.params.locationID)
    }
  },


  mounted () {
    this.$store.dispatch('loadLocation', this.$route.params.locationID)
  },

  methods: {
    editLocation () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationAlert = { status: true, type: 'warning', message: 'Cannot Submit Form. Invalid Fields Detected.'}
      } else {
        this.$store.dispatch('editLocation', { data: this.location })
          .then( () => {
            this.validationAlert = { status: true, type: 'success', message: 'Location Updated Successfully!'}
          })
      }
    },

    deletereview($id) {
      this.$store.dispatch('deleteReview', { id: $id, locationId: this.$route.params.locationID })
    },

    reCrawlReviews () {
      this.$store.dispatch('getLocationReviews', { data: this.location })
    },

    resetForm () {
      this.$v.$reset()
      this.$store.commit('setAddLocation', false)
    },

    reviewsPaginate (args) {
      this.$store.dispatch('loadReviews', args)
    }
  }
}
</script>
