<template>
  <v-layout>
    <v-container class="d-flex justify-center">
      <v-card class="d-block pa-5 display-4 align-self-center" width="320">
        <v-card-title primary-title>
            <h1>Error 404</h1>
        </v-card-title>
        <v-divider class="mt-8 mb-5" />
        <v-card-text class="d-block headline">
          {{ message[Math.floor(Math.random() * message.length)] }}
        </v-card-text>
        <v-divider class="mt-8 mb-5" />
        <v-card-actions class="overline centered">
          Page not found :(
        </v-card-actions>
      </v-card>
    </v-container>
  </v-layout>  
</template>

<script>
export default {
  name: 'Youtube',

  data () {
    return {
        message: [
            'Not until we are lost do we begin to understand ourselves.',
            'In the middle of the journey of our life I came to myself within a dark wood where the straight way was lost.',
            'When you\'re feeling lost, take heart. It\'s just your brain gathering the information it needs to make good decisions.',
            'Feeling lost? Take a dream and convert it into small goals, then start taking the steps to hit those goals.',
            'It is an ironic habit of human beings to run faster when we have lost our way.',
            'I need to have a reason why I\'m doing something. Otherwise I\'m lost.',
            'When all else is lost, the future still remains.',
            'You got to go down a lot of wrong roads to find the right one.',
            'When I feel lost and can\'t make a decision, I just stop and get quiet. I take a time-out.',
            'The art of being bored is lost.',
            'Feeling lost? Good! Now you get to walk new paths that lead to much better places.',
            'I lost my head for a little while.',
            'Be Thankful for all things in life. Even for the bad things. SOMETIMES the worst situations in life turn out to be the best things that ever happened to us.',
            'The best thing we can do is to make wherever we\'re lost in look as much like home as we can.',
            'To be lost is as legitimate a part of your process as being found.',
            'Don\'t dwell on what went wrong. Instead, focus on what to do next. Spend your energies on moving forward toward finding the answer.'

        ]
    }
  }
}
</script>

<style>
/* all styles should be in sfs-ss/vue/src/css --Japz */
</style>
