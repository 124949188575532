<template>
  <v-layout>
    <v-container fluid>
      <v-card>
        <v-card-title>
          <h2 class="pa-1">
            <v-icon color="tertiary">insert_chart</v-icon>
            DASHBOARD
          </h2>
          <v-spacer></v-spacer>
          <v-alert type="info" tile color="info darken-2">
            Last crawl started at <span class="yellow--text">{{ dashboard.data.logs['start-time'] }}</span> and
            ended at <span class="yellow--text">{{ dashboard.data.logs['end-time'] }}</span>
          </v-alert>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-container fluid>
            <v-layout row wrap>
              <v-flex md3 v-if="dashboard.data.logs.totalReviewAdded">
                <v-card class="ml-2 mr-2" tile>
                  <v-card-title class="info justify-center">
                    <v-icon class="mr-2 tertiary--text">gps_fixed</v-icon>
                    Number of Locations
                  </v-card-title>
                  <v-card-text>
                    <h4 style="font-size: 4em; margin: 0.5em 0" align="center">{{ totals.locations.count.toLocaleString() }}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md3 v-if="dashboard.data.logs.totalLocationsQueried">
                <v-card class="ml-2 mr-2" tile>
                  <v-card-title class="info justify-center">
                    <v-icon class="mr-2 tertiary--text">assignment_turned_in</v-icon>
                    Locations with GMB ID
                  </v-card-title>
                  <v-card-text>
                    <h4 style="font-size: 4em; margin: 0.5em 0" align="center">{{ dashboard.data.logs.totalLocationsQueried.toLocaleString() }}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md3 v-if="dashboard.data.logs.totalLocationsReviewsAdded">
                <v-card class="ml-2 mr-2" tile>
                  <v-card-title class="info justify-center">
                    <v-icon class="mr-2 tertiary--text">assignment</v-icon>
                    Locations with Review
                  </v-card-title>
                  <v-card-text>
                    <h4 style="font-size: 4em; margin: 0.5em 0" align="center">{{ dashboard.data.logs.totalLocationsReviewsAdded.toLocaleString() }}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md3 v-if="dashboard.data.logs.totalReviewAdded">
                <v-card class="ml-2 mr-2 mb-8" tile>
                  <v-card-title class="info justify-center">
                    <v-icon class="mr-2 tertiary--text">autorenew</v-icon>
                    New Reviews
                  </v-card-title>
                  <v-card-text>
                    <h4 style="font-size: 4em; margin: 0.5em 0" align="center">{{ dashboard.data.logs.totalReviewAdded.toLocaleString() }}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md12>
                <v-divider class="mt-5 mb-5"></v-divider>
              </v-flex>

              <v-flex md4 class="text-center">
                <v-btn-toggle class="mt-4 mb-5">
                  <v-btn>
                    <v-icon class="mr-1">language</v-icon>
                    {{ totals.country.count.toLocaleString() }} Countries
                  </v-btn>

                  <v-btn>
                    <v-icon class="mr-1">location_on</v-icon>
                    {{ totals.state.count.toLocaleString() }} States
                  </v-btn>

                  <v-btn>
                    <v-icon class="mr-1">location_city</v-icon>
                    {{ totals.city.count.toLocaleString() }} Cities
                  </v-btn>
                </v-btn-toggle>

                <countries-chart :data="totals.country.data" />
              </v-flex>
              <v-flex md8>
                <states-chart :data="this.countryProp" :country="this.selectedCountry" @countryListener="this.setCountry" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>

    </v-container>
  </v-layout>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import CountriesChart from '@/components/charts/countries.vue'
import StatesChart from '@/components/charts/states.vue'
// import router from '@/router/router'

export default {
  name: 'Dashboard',

  data () {
    return {
      selectedCountry: 'USA',
      countryProp: null,
      totals: {
        locations: {
          count: 0,
          data: null
        },
        country: {
          count: 0,
          data: null
        },
        state: {
          count: 0,
          data: null
        },
        city: {
          count: 0,
          data: null
        },
      }
    }
  },

  props: {
   data: Array
  },

  components: {
    'countries-chart': CountriesChart,
    'states-chart': StatesChart
  },

  computed: mapState([
    'dashboard'
  ]),

  watch: {
    dashboard: {
      handler: function (newData) {
        if (!newData) return
        this.allTotals(newData.data.dashboard)
      },
      deep: true
    }

  },

  created () {
    this.$store.dispatch('loadDashboard')
  },

  methods: {
    allTotals (data) {
      if (!data.length) return

      let country = data.map(function(country) {
        return { count: country['total'], country: country['country'] }
      })

      let states = data.map(function(country) {
        let state = country.states.map(function(s) {
          return { count: s['totalLocations'], state: s['state'] }
        })
        return state
      })

      let city = data.map(function(country) {
        let state = country.states.map(function(s) {
          return Object.keys(s['cities']).length
        })
        return _.sum(state)
      })

      this.totals = {
        locations: {
          count: _.sumBy(data, function (location) {
            return location['total']
          }),
          data: null
        },
        country: {
          count: country.length,
          data: country
        },
        state: {
          count: _.sumBy(states, function (location) {
            return location.length
          }),
          data: states
        },
        city: {
          count: _.sum(city),
          data: null
        },
      }

      this.statesData(this.selectedCountry)
    },

    statesData (args) {
      let country = _.filter(this.dashboard.data.dashboard, {country: args })

      let states = _.sortBy(
        country[0].states.map(function(s) {
          return { count: s['totalLocations'], state: s['state'] }
        }),
        ['state']
      )
      this.countryProp = states
    },

    setCountry (args) {
      this.statesData(args)
    }
  }
}
</script>
