import { sfl_api } from "@/helpers/sfl-api.js"
// import Locations from '@/temp/static-locations.json'

export const locations  = {
  namespaced: false,
  state: {
    data: {
      locations: [],
    },
  },

  actions: {
    loadLocations ({ commit }, args=null) {

      if (args) {
        let paramPage = (args.page) ? '?page=' + args.page : ''
        let paramPerPage = (args.perPage) ? '&pagesize=' + args.perPage : ''
        var params = paramPage + paramPerPage
      } else {
        params = '?page=1&pagesize=25'
      }

      sfl_api('get', '/locations' + params, 'setLocations')
      .then(response => {
        commit('setLocations', response.data.data)
        // return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    diffLocations ( { commit } ) {
      // if (args) {
      //   let paramPage = (args.page) ? '?page=' + args.page : ''
      //   let paramPerPage = (args.perPage) ? '&pagesize=' + args.perPage : ''
      //   var params = paramPage + paramPerPage
      // } else {
      //   params = '?page=1&pagesize=25'
      // }

      sfl_api('get', '/latestupload', 'setLocations')
      .then(response => {
        commit('setLocations', response.data)
        // return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
        return error
      })
    },    

    searchLocations ({ commit }, args=null) {
      if (args) {
        let paramPage = (args.page) ? '?page=' + args.page : ''
        let paramPerPage = (args.pagesize) ? '&pagesize=' + args.pagesize : ''
        let paramKeyword = (args.keyword) ? '&search=' + args.keyword : ''
        var params = paramPage + paramPerPage + paramKeyword
      } else {
        return
      }

      sfl_api('get', '/locations' + params, 'setLocations')
      .then(response => {
        commit('setLocations', response.data.data)
        // return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    }
  },

  mutations: {
    setLocations (state, args) {
      state.data = args
    }
  },

  getters: {
    locations: state => state.data,
  }
}
