// import DEFAULT from '@/router/routes/_DEFAULT'
import dashboard from '@/router/routes/dashboard'

import user from '@/router/routes/user'
import locations from '@/router/routes/locations'
import csvimport from '@/router/routes/import'

import changePassword from '@/router/routes/change-password'
import e404 from '@/router/routes/e404'
import login from '@/router/routes/login'
import forgot from '@/router/routes/forgot'
import serve from '@/router/routes/serve'

// SORT THIS AS HOW YOU WANT IT TO SHOW IN THE SIDEBAR
export default [
  // ...DEFAULT,
  // FOUND IN SIDEBAR
  ...dashboard,
  ...locations,
  ...user,
  ...csvimport,

  // FOUND IN HEADER

  // FOUND NOWHERE
  ...login,
  ...forgot,
  ...e404,
  ...serve,
  ...changePassword
]
