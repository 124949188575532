import Vue from 'vue'
import App from './App.vue'

// ESSENTIAL GLOBAL DEPENDANCIES
import router from '@/router/router'
import vuetify from '@/plugins/vuetify'
import Vuelidate from 'vuelidate'

import  "@/helpers/global-filter";

// STATE MANAGEMENT [SEE vuex]
import { store } from '@/store/store'

import './registerServiceWorker'

Vue.use(Vuelidate)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
