import  Vue  from  "vue"

Vue.filter("exceedsNow",  function(value, today)  {
    if (!value) {
      return ''
    } else {
      let dateTodayRaw = today.split("-")
      let datetoday = dateTodayRaw[0] + dateTodayRaw[1] + dateTodayRaw[2]
      return (datetoday >= value) ? 'priority_high' : ''
    }
})

Vue.filter("dateFormat",  function(value)  {
  if (!value) {
    return ''
  } else {
    return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
  }
})

Vue.filter("limitText",  function(value)  {
  if (!value) {
    return ''
  } else {
    return (value.length > 20) ? value.substring(0,20) + '...' : value
  }
})

Vue.filter("filterTitle",  function(value)  {
  let result = value.replace( /([A-Z])/g, " $1" )
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult.toUpperCase()
})
