import { confirm } from '@/store/stores/confirm'
import { dashboard } from '@/store/stores/dashboard'
import { locations } from '@/store/stores/locations'
import { location } from '@/store/stores/location'
import { reviews } from '@/store/stores/reviews'
import { users } from '@/store/stores/users'

export default {
  confirm,
  dashboard,
  location,
  locations,
  reviews,
  users
}
