import Import from '@/components/pages/import/Import'
import ImportForm from '@/components/pages/import/ImportForm'
import ImportDiff from '@/components/pages/import/ImportDiff'
import ImportNew from '@/components/pages/import/ImportNew'

export default [
  {
    path: '/import',
    name: 'import',
    component: Import,
    meta: {
      accessLvl: ['superadmin'],
      icon: 'cloud_upload',
      sidebar: true,
      header: false,
      title: 'Import',
    },

    children: [
      {
        path: '/import/form',
        name: 'import.form',
        component: ImportForm,
        meta: {
          accessLvl: ['superadmin'],
          icon: 'attach_file',
          sidebar: true,
          header: false,
          title: 'Import CSV'
        },
      },    
      {
        path: '/import/diff',
        name: 'import.diff',
        component: ImportDiff,
        meta: {
          accessLvl: ['superadmin'],
          icon: 'compare_arrows',
          sidebar: true,
          header: false,
          title: 'Import Difference'
        },
      },
      {
        path: '/import/new',
        name: 'import.new',
        component: ImportNew,
        meta: {
          accessLvl: ['superadmin'],
          icon: 'notifications_active',
          sidebar: true,
          header: false,
          title: 'New Imports'
        },
      },      
      
    ],    
  }
]
