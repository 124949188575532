export default [
  '#FED4E7',
  '#E5B769',
  '#8DA7BE',
  '#D8CC34',
  '#FFC0CB', // chart stuffs
  '#FFA500', // chart stuffs
  '#ADD8E6', // chart stuffs
  '#D3D3D3', // chart stuffs
  '#FF0000', // chart stuffs
  '#e14e38',
  '#554640',
  '#CDE6F5',
  '#87919E',
  '#28464B',
  '#326771',
  '#2C8C99',
  '#42D9C8',
  '#F7F0F5',
  '#DECBB7',
  '#5C5552',
  '#433633',
  '#EDC9FF'
]
