export default {
  status: 'active',
  locationName: null,
  gafId: null,
  gmbId: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  data: {}
}
