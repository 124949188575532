<template>
  <v-navigation-drawer
    v-model="sidebar"
    app
    clipped
    id="sidebar"
    mobile-breakpoint="768"
    width="300"
    color="white"
    expand-on-hover
    floating
    mini-variant
  >
    <v-list
      dense
      nav
      tile
      class="pr-0"
    >
      <v-list-item-group>
        <template v-for="route in routes">
          <template v-if="route.meta.accessLvl.includes(user.userLvl)">
            <v-list-item
              v-if="!route.children"
              :key="route.title"
              active-class="active-sidebar"
              :to="route.path"
            >
              <v-list-item-icon>
                <v-icon>{{ route.meta.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content align-left>
                <v-list-item-title>
                  {{ route.meta.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-else
              :key="route.title"
              id="sidebarWithChild"
              class="mb-2"
              light
              append-icon="keyboard_arrow_downs"
              active-class="active-sidebar-parent"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item
                  class="pl-0"
                  :ripple="false"
                >
                  <v-list-item-icon>
                    <v-icon>{{ route.meta.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ route.meta.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template
                v-for="child in route.children"
              >
                <v-list-item
                  v-if="child.meta.sidebar"
                  :key="child.path"
                  :to="child.path"
                  class="submenu"
                >
                  <v-list-item-icon>
                    <v-icon>{{ child.meta.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content align-left>
                    <v-list-item-title>
                      {{ child.meta.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

            </v-list-group>
          </template>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash'
import router from '@/router/router'
import { mapState } from 'vuex'

export default {
  name: 'SideBar',

  data () {
    return {
      sidebar: true,
      routes: _.filter(router.options.routes, {meta: { sidebar: true}}),
    }
  },

  watch: {
    // do something
  },

  mounted () {
    this.$store.dispatch('loadUser') //see store/index.js Obj.actions
   },

  computed: mapState([
    'user' //see store/index.js Obj.state
  ]),

  methods: {
    // do something
  }

}
</script>
