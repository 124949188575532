<template>
  <v-container
    fluid
    fill-height
    class="pt-0 pb-0"
  >
    <v-layout
      align-center
      justify-center
      row
      class="login"
    >
      <v-flex
        md6
        xs12
        fill-height
        class="hero"
        :style="{ backgroundImage: `url(${bgHero})` }"
      >
      </v-flex>

      <v-flex
        md6
        xs12
        fill-height
        class="loginForm"
        :style="{ backgroundImage: `url(${bgLogin})` }"
      >
        <v-card
          elevation="0"
          class="pt-5"
          color="transparent"
        >
          <h1 class="mb-5" color="primary">
            <img
              src="@/assets/gaf-logo.png"
              width="200px"
              height="200px"
            />
          </h1>

          <v-form @submit.prevent="login">
            <v-card-text>

              <strong>Email Address</strong>
              <v-text-field
                outlined
                background-color="white"
                color="tertiary"
                v-model="$v.input.username.$model"
                placeholder="Your Email Address"
                name="login"
                append-icon="mail"
                type="text"
                :error="$v.input.username.$anyError"
                :persistent-hint="$v.input.username.$invalid"
                :error-messages="$v.input.username.$anyError ? 'Username is too short' : ''"
                class="rounded-0"
              />

              <strong>Password</strong>
              <v-text-field
                outlined
                background-color="white"
                color="tertiary"
                v-model="$v.input.password.$model"
                append-icon="lock"
                type="password"
                name="password"
                placeholder="Your Password"
                :error="$v.input.password.$anyError"
                :persistent-hint="$v.input.password.$invalid"
                :error-messages="$v.input.password.$anyError ? 'Password is too short' : ''"
                class="rounded-0"
              />

              <h3 align="right">
                <router-link to="forgot-password">
                  Forgot Password?
                </router-link>
              </h3>

              <sfl-alert />
            </v-card-text>

            <v-card-actions
              class="justify-center"
            >
                <v-btn
                  type="submit"
                  dark
                  color="primary"
                  ripple
                  class="signIn"
                >
                  Sign In
                </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Alert from '@/components/partials/Alert.vue'
import { sfl_auth } from "@/helpers/sfl-auth.js"
import { required, minLength } from 'vuelidate/lib/validators'
import bgHero from "@/assets/hero.jpg";
import bgLogin from "@/assets/login.gif";


export default {
  name: 'Login',
  data () {
    return {
      show: false,
      input: {
        username: null,
        password: null
      },
      submitStatus: null,
      bgHero: bgHero,
      bgLogin: bgLogin
    }
  },

  validations: {
    input: {
      username: {
        required,
        minLength: minLength(4),
      },
      password: {
        required,
        minLength: minLength(4),
      }
    }
  },

  components: {
    'sfl-alert': Alert
  },

  mounted () {
    // do ssomething
  },

  methods: {
    login () {
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        this.$store.commit('setAlert', {
          'status': true,
          'message': 'Cannot submit form. Invalid fields detected.',
          'type': 'warning',
          'icon': 'info'
        })
      } else {
        this.submitStatus = 'PENDING'
        sfl_auth(this.input)
      }
    },
  },

  computed: mapState([
    'alert',
  ]),
}
</script>
