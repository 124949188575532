import { sfl_api } from "@/helpers/sfl-api.js"

export const dashboard  = {
  namespaced: false,
  state: {
    data: {
      dashboard: [],
      logs: {}
    },
  },

  actions: {
    loadDashboard ({ commit }) {
      sfl_api('get', '/dashboard', 'setDashboard')
      .then(response => {
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    }
  },

  mutations: {
    setDashboard (state, args) {
      state.data = args
    },
  },

  getters: {
    dashboard: state => state.data
  }
}
