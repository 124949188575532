<template>
  <v-layout>
    <v-container fluid>
      <v-card class="pa-5">
        This is the default template the you can reuse --Japz
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: 'Youtube'
}
</script>

<style>
/* all styles should be in sfs-ss/vue/src/css --Japz */
</style>
