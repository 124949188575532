<template>
  <v-layout>
    <v-container fluid>
      <v-form @submit.prevent="addNewUser" ref="form">
        <v-card>
          <v-card-title>
            <h3>
              <v-icon class="mr-2" color="tertiary">person_add</v-icon>
              ADD USER
            </h3>

            <v-spacer></v-spacer>

            <v-btn-toggle class="mr-5">
              <v-btn small to="/users/list">
                <v-icon small class="mr-1">supervisor_account</v-icon>
                Users List
              </v-btn>
            </v-btn-toggle>
          </v-card-title>

          <v-divider />

          <v-card-text class="pt-5">
            <v-layout wrap>
              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tl" id="userAddHeadingsLeft">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">contact_mail</v-icon>
                      USER DETAILS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="First Name"
                          prepend-inner-icon="perm_identity"
                          v-model="$v.newUser.firstName.$model"
                          :error="$v.newUser.firstName.$anyError"
                          :persistent-hint="$v.newUser.firstName.$invalid"
                          :error-messages="$v.newUser.firstName.$anyError ? 'First Name is required' : ''"
                          @blur="$v.newUser.firstName.$touch()"
                          :color="$v.newUser.firstName.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Last Name"
                          prepend-inner-icon="perm_identity"
                          v-model="$v.newUser.lastName.$model"
                          :error="$v.newUser.lastName.$anyError"
                          :persistent-hint="$v.newUser.lastName.$invalid"
                          :error-messages="$v.newUser.lastName.$anyError ? 'Last Name is required' : ''"
                          @blur="$v.newUser.lastName.$touch()"
                          :color="$v.newUser.lastName.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Email"
                          prepend-inner-icon="mail"
                          v-model="$v.newUser.email.$model"
                          :error="$v.newUser.email.$anyError"
                          :persistent-hint="$v.newUser.email.$invalid"
                          :error-messages="$v.newUser.email.$anyError ? 'A Valid Email is required' : ''"
                          @blur="$v.newUser.email.$touch()"
                          :color="$v.newUser.email.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tr" id="userAddHeadingsRight">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">lock</v-icon>
                      USER CREDENTIALS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Username"
                          prepend-inner-icon="lock"
                          v-model="$v.newUser.username.$model"
                          :error="$v.newUser.username.$anyError"
                          :persistent-hint="$v.newUser.username.$invalid"
                          :error-messages="$v.newUser.username.$anyError ? 'Username with a minimum of 5 characters is required' : ''"
                          @blur="$v.newUser.username.$touch()"
                          :color="$v.newUser.username.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>

                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Password"
                          prepend-inner-icon="lock"
                          v-model="$v.newUser.password.$model"
                          :error="$v.newUser.password.$anyError"
                          :persistent-hint="$v.newUser.password.$invalid"
                          :error-messages="$v.newUser.password.$anyError ? 'Password with a minimum of 5 characters is required' : ''"
                          @blur="$v.newUser.password.$touch()"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :color="$v.newUser.password.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>

                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Confirm Password"
                          prepend-inner-icon="lock"
                          v-model="$v.newUser.passwordConfirm.$model"
                          :error="$v.newUser.passwordConfirm.$anyError"
                          :persistent-hint="$v.newUser.passwordConfirm.$invalid"
                          :error-messages="$v.newUser.passwordConfirm.$anyError ? 'This must match password' : ''"
                          @blur="$v.newUser.passwordConfirm.$touch()"
                          :type="show ? 'text' : 'password'"
                          @click:append="showConfirm = !showConfirm"
                          :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                          :color="$v.newUser.passwordConfirm.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

                <v-layout wrap v-if="!submitted">
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="text-center pa-5 d-block">
            <v-alert v-if="this.alert.status" :type="this.alert.type" :value="this.alert.status">
                {{ this.alert.message }}
            </v-alert>

            <v-btn type="submit" color="tertiary" dark class="pr-3" x-large v-if="users.data === null || loadingStatus.color === 'error'">
              <v-icon class="mr-3">add_circle</v-icon>
              Add New User
            </v-btn>
            <v-alert v-else type="success">
                <p>New User Successsfully Added!</p>
                <p>
                  <a href="#" @click="resetForm" class="warning--text">
                    <v-icon color="warning">add_circle</v-icon>
                    Add Another User
                  </a>
                </p>
            </v-alert>
          </v-card-actions>
        </v-card>
      </v-form>
      </v-container>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
// import _ from 'lodash'

export default {
  name: 'UsersAdd',

  data () {
    return {
      show: false,
      showConfirm: false,
      newUser: {
        username: null,
        password: null,
        passwordConfirm: null,
        userLvl: 'superadmin',
        firstName: null,
        lastName: null,
        email: null,
        data: {}
      },
      submitted: false,
      alert: {
        status: false,
        type: null,
        message: null
      }
    }
  },

  validations: {
    newUser: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      username: { required, minLength: minLength(5) },
      password: { required, minLength: minLength(5) },
      passwordConfirm: { sameAsPassword: sameAs('password') }
    }
  },

  computed: mapState([
    'users',
    'loadingStatus'
  ]),

  mounted () {
    this.$store.commit('setUsers', null)
  },

  methods: {
    addNewUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.alert = { status: true, type: 'warning', message: 'Cannot Submit Form. Invalid Fields Detected.'}
      } else {
        this.alert = { status: false, type: null, message: null}
        this.$store.dispatch('addUser', { data: this.newUser })
      }
    },

    resetForm () {
      this.$v.$reset()
      this.$store.commit('setUsers', null)
      this.alert = { status: false, type: null, message: null }
      this.newUser = {
        username: null,
        password: null,
        passwordConfirm: null,
        userLvl: 'superadmin',
        firstName: null,
        lastName: null,
        email: null,
        data: {}
      }
    }
  }
}
</script>
