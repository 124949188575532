<template>
  <v-layout>
    <v-container fluid>
      <v-card rounded shaped>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="locations.data.data"
            :page.sync="locations.data.current_page"
            :server-items-length="locations.data.total"
            :search="search"
            hide-default-footer
            class="mb-5"
            disable-sort
            mobile-breakpoint="768"
          >
            <template v-slot:top>
              <v-card-title>
                <h3>
                  <template v-if="$router.app._route.name === 'locations.search'">
                    <v-icon color="tertiary">search</v-icon>
                    <span class="primary--text">{{ locations.data.total.toLocaleString() }}</span> Search Results for: <em><u>{{ $router.app._route.params.keyword }}</u></em>
                  </template>
                  <template v-else>
                    <v-icon color="tertiary">format_list_numbered</v-icon>
                    LOCATIONS LIST

                    <v-btn small class="ml-8 mt-n1" @click="exportCSV()">
                      <v-icon>download</v-icon>
                      Export Locations to CSV
                    </v-btn>
                  </template>
                </h3>
                <div class="flex-grow-1"></div>

                <v-btn-toggle class="mr-5">
                  <v-btn small to="/locations/add">
                    <v-icon small class="mr-1">add_location</v-icon>
                    Add New Location
                  </v-btn>
                </v-btn-toggle>

                <v-form @submit.prevent="searchLocations">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hint="Search locations using id, name, city, state, zip and country as keyword"
                    persistent-hint
                    clearable
                    @click:clear="resetLocations()"
                  />
                </v-form>
              </v-card-title>
            </template>

            <template v-slot:item.gafId="{ item }">
              <router-link :to="{ name: 'locations.edit', params: { locationID: item.id }}">
                {{ item.gafId }}
              </router-link>
            </template>

            <template v-slot:item.gmbId="{ item }">
                <span v-if="!item.gmbId">
                  null
                </span>
                <span v-else>
                  {{ item.gmbId|limitText }}
                </span>
            </template>

            <template v-slot:item.status="{ item }">
                {{ item.status.toUpperCase() }}
            </template>

            <template v-slot:no-data>
                <tr>
                  <td>No data to show.</td>
                </tr>
            </template>

            <template v-slot:footer>
              <v-divider class="mt-3 mb-5"></v-divider>
              <v-layout id="locationsListPagination">
                <v-spacer></v-spacer>
                <v-flex md2 class="pt-6 mr-5 d-block-md">
                  <v-select
                    :items="itemsPerPage"
                    :label="'Showing ' + locations.data.per_page + ' items per page'"
                    v-model="locations.data.per_page"
                    @input="locationsPaginate({ perPage: locations.data.per_page, page: locations.data.current_page })"
                  />
                </v-flex>
                <v-pagination
                  v-model="locations.data.current_page"
                  :length="locations.data.last_page"
                  total-visible="10"
                  color="tertiary"
                  @input="locationsPaginate({ perPage: locations.data.per_page, page: locations.data.current_page })"
                  class="mt-8 mb-8"
                />
              </v-layout>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import { sfl_api } from "@/helpers/sfl-api.js"
import { mapState } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'LocationsList',

  data () {
    return {
      search: '',
      tableData: [],
      itemsPerPage: [25, 50, 100, 250],
      headers: [
        { text: 'GAF ID', value: 'gafId', width: '10%' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'PLACE ID', value: 'gmbId', width: '10%' },
        { text: 'Name', value: 'locationName', width: '30%' },
        { text: 'City', value: 'city', width: '10%' },
        { text: 'State', value: 'state', width: '10%' },
        { text: 'Zip', value: 'zip', width: '10%' },
        { text: 'Country', value: 'country', width: '10%' },
        { text: 'Average Rating', value: 'totalRating', width: '5%' },
        { text: 'Total Reviews', value: 'totalReviews', width: '5%' },
      ],
    }
  },

  computed: mapState([
    'locations'
  ]),

  watch: {
    // do something
  },

  beforeRouteLeave  (to, from, next) {
    next()
    if(this.$router.app._route.name === "locations.list") {
      this.search = null
      this.$store.dispatch('loadLocations')
    }
  },

  mounted () {
    if (this.$router.app._route.name === 'locations.search') {
      this.$store.dispatch('searchLocations', { pagesize: 25, keyword: this.$router.app._route.params.keyword })
    } else {
      this.$store.dispatch('loadLocations')
    }
  },

  methods: {
    goto (where) {
      // this.$router.push('/tag/' + this.$route.params.department + '/' + where )
      return where
    },

    exportCSV () {
      sfl_api('get', '/downloadcsv/', null, null, null, 'Please wait while data is being prepared. This may take a couple of minutes depending on the size of the current database.')
      .then(response => {
        return response.data
      })
      .catch( error => {
        this.$store.commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    resetLocations () {
      this.$router.replace('/locations/list')
      this.$store.dispatch('loadLocations')
    },

    searchLocations () {
      this.$router.push('/locations/search/' + this.search )
      this.$store.dispatch('searchLocations', { pagesize: 25, keyword: this.search, page: 1 })
    },

    locationsPaginate (args) {
      if (this.$router.app._route.name === 'locations.search') {
        this.$store.dispatch('searchLocations', { pagesize: this.locations.data.per_page, keyword: this.$router.app._route.params.keyword, page: this.locations.data.current_page })
      } else {
        this.$store.dispatch('loadLocations', args)
      }
    }
  }
}
</script>
