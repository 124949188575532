import Dashboard from '@/components/pages/Dashboard'

export default [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          icon: 'home',
          sidebar: true,
          header: true,
          accessLvl: ['superadmin'],
          title: 'Dashboard'
        },
    }
]
