import { sfl_api } from "@/helpers/sfl-api.js"
// import Locations from '@/temp/static-locations.json'

export const reviews  = {
  namespaced: false,
  state: {
    data: {
      reviews: {},
    },
  },

  actions: {
    loadReviews ({ commit }, args) {
      sfl_api('get', '/reviews/' + args.id + '?page=' + args.page + '&pagesize=' + args.pagesize, null)
      .then(response => {
        commit('setReviews', response.data)
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },
    deleteReview({ dispatch, commit }, args) {
      sfl_api('get', '/deletereview/' + args.id, null, null)
      .then(response => {
        commit('setAlert', {
          'status': true,
          'type': 'success',
          'message': 'Review has been deleted!',
          'persistent': false
        })
        dispatch('loadLocation', args.locationId)
        dispatch('loadLoading', false)
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },
  },

  mutations: {
    setReviews (state, args) {
      state.data.reviews = args
    }
  },

  getters: {
    reviews: state => state.data,
  }
}
