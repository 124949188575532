<template>
  <v-layout>
    <v-container fluid>
      <v-form @submit.prevent="addNewLocation" ref="form">
        <v-card>
          <v-card-title>
            <h3>
              <v-icon class="mr-2" color="tertiary">add_location</v-icon>
              ADD LOCATION
            </h3>

            <v-spacer></v-spacer>

            <v-btn-toggle>
              <v-btn small to="/locations/list">
                <v-icon small class="mr-1">gps_fixed</v-icon>
                All Locations
              </v-btn>
            </v-btn-toggle>
          </v-card-title>

          <v-divider />

          <v-card-text class="pt-5">
            <v-layout wrap>
              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tl locationAddHeadings" id="locationAddHeadingsLeft">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">contact_mail</v-icon>
                      LOCATION DETAILS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Location Name"
                          prepend-inner-icon="perm_identity"
                          v-model="$v.newLocation.locationName.$model"
                          :error="$v.newLocation.locationName.$anyError"
                          :persistent-hint="$v.newLocation.locationName.$invalid"
                          :error-messages="$v.newLocation.locationName.$anyError ? 'Location Name is required' : ''"
                          @blur="$v.newLocation.locationName.$touch()"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="GAF ID"
                          prepend-inner-icon="web"
                          v-model="$v.newLocation.gafId.$model"
                          :error="$v.newLocation.gafId.$anyError"
                          :persistent-hint="$v.newLocation.gafId.$invalid"
                          :error-messages="$v.newLocation.gafId.$anyError ? 'GAF ID is required' : ''"
                          @blur="$v.newLocation.gafId.$touch()"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="GMB ID"
                          prepend-inner-icon="web"
                          v-model="$v.newLocation.gmbId.$model"
                          :error="$v.newLocation.gmbId.$anyError"
                          :persistent-hint="$v.newLocation.gmbId.$invalid"
                          :error-messages="$v.newLocation.gmbId.$anyError ? 'GMB ID is required' : ''"
                          @blur="$v.newLocation.gmbId.$touch()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tr" id="locationAddHeadingsRight">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">contact_mail</v-icon>
                      LOCATION ADDRESS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md6 class="pa-2">
                        <v-text-field
                          label="Address Line 1"
                          prepend-inner-icon="location_city"
                          v-model="$v.newLocation.address1.$model"
                          :error="$v.newLocation.address1.$anyError"
                          :persistent-hint="$v.newLocation.address1.$invalid"
                          :error-messages="$v.newLocation.address1.$anyError ? 'Address is required' : ''"
                          @blur="$v.newLocation.address1.$touch()"
                        />
                      </v-flex>
                      <v-flex md6 class="pa-2">
                        <v-text-field
                          label="Address Line 2"
                          prepend-inner-icon="location_city"
                          v-model="$v.newLocation.address2.$model"
                          :error="$v.newLocation.address2.$anyError"
                          persistent-hint
                          hint="Optional"
                        />
                      </v-flex>

                      <v-flex md4 class="pa-2">
                        <v-text-field
                          label="City"
                          prepend-inner-icon="location_on"
                          v-model="$v.newLocation.city.$model"
                          :error="$v.newLocation.city.$anyError"
                          :persistent-hint="$v.newLocation.city.$invalid"
                          :error-messages="$v.newLocation.city.$anyError ? 'City is required' : ''"
                          @blur="$v.newLocation.city.$touch()"
                        />
                      </v-flex>
                      <v-flex md4 class="pa-2">
                        <v-text-field
                          label="State"
                          prepend-inner-icon="location_on"
                          v-model="$v.newLocation.state.$model"
                          :error="$v.newLocation.state.$anyError"
                          :persistent-hint="$v.newLocation.state.$invalid"
                          :error-messages="$v.newLocation.state.$anyError ? 'State is required' : ''"
                          @blur="$v.newLocation.state.$touch()"
                        />
                      </v-flex>
                      <v-flex md4 class="pa-2">
                        <v-text-field
                          label="Zip"
                          prepend-inner-icon="location_on"
                          v-model="$v.newLocation.zip.$model"
                          :error="$v.newLocation.zip.$anyError"
                          :persistent-hint="$v.newLocation.zip.$invalid"
                          :error-messages="$v.newLocation.zip.$anyError ? 'Zip is required' : ''"
                          @blur="$v.newLocation.zip.$touch()"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Country"
                          prepend-inner-icon="language"
                          v-model="$v.newLocation.country.$model"
                          :error="$v.newLocation.country.$anyError"
                          :persistent-hint="$v.newLocation.country.$invalid"
                          :error-messages="$v.newLocation.country.$anyError ? 'Country is required' : ''"
                          @blur="$v.newLocation.country.$touch()"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

                <v-layout wrap v-if="!submitted">
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="text-center pa-5 d-block">
            <v-alert v-if="location.data.errorLocation" type="warning" class="mb-2">
                Cannot Add Location. GAF ID already exists.
            </v-alert>

            <v-alert v-if="this.alert.status" :type="this.alert.type" :value="this.alert.status">
                {{ this.alert.message }}
            </v-alert>

            <v-btn type="submit" color="tertiary" dark class="pr-3" x-large v-if="!location.data.addLocation">
              <v-icon class="mr-3">add_circle</v-icon>
              Add New Location
            </v-btn>
            <v-alert v-else type="success">
                <p>New Location Successsfully Added!</p>
                <p>
                  <a href="#" @click="resetForm" class="warning--text">
                    <v-icon color="warning">add_circle</v-icon>
                    Add Another Location
                  </a>
                </p>
            </v-alert>
          </v-card-actions>
        </v-card>
      </v-form>
      </v-container>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import blankLocation from '@/constants/location.js'
import { required } from 'vuelidate/lib/validators'
// import _ from 'lodash'

export default {
  name: 'LocationsAdd',

  data () {
    return {
      newLocation: blankLocation,
      submitted: false,
      alert: {
        status: false,
        type: null,
        message: null
      }
    }
  },

  validations: {
    newLocation: {
      locationName: { required },
      gafId: { required },
      gmbId: { required },
      address1: { required },
      address2: false,
      city: { required },
      state: { required },
      zip: { required },
      country: { required }
    }
  },

  computed: mapState([
    'location'
  ]),

  mounted () {
    this.$store.commit('setAddLocation', false)
    this.$store.commit('setErrorLocation', false)
  },

  methods: {
    addNewLocation () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.alert = { status: true, type: 'warning', message: 'Cannot Submit Form. Invalid Fields Detected.'}
      } else {
        this.$store.dispatch('addLocation', { data: this.newLocation })
      }
    },

    resetForm () {
      this.$v.$reset()
      this.$store.commit('setAddLocation', false)
      this.$store.commit('setErrorLocation', false)
      this.alert = { status: false, type: null, message: null }
      this.newLocation = this.blankLocation
    }
  }
}
</script>
