import { sfl_api } from "@/helpers/sfl-api.js"

export const users  = {
  namespaced: false,
  state: {
    data: {
      users: {}
    },
  },

  actions: {
    loadUser ({ commit }, args = null) {
      if(!args) return
      sfl_api('get', '/users/' + args, null)
      .then(response => {
        commit('setUsers', response.data.data)
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    loadUsers ({ commit }) {
      sfl_api('get', '/users', null)
      .then(response => {
        commit('setUsers', response.data.data)
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    addUser ({ commit }, args) {
      sfl_api('post', '/register', null, null, args.data)
      .then( (response) => {
        commit('setUsers', response.data.data)
        return response
      })
      .catch( error => {
        commit('setUsers', error.data.data)
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    updateUser ({ commit }, args) {
      sfl_api('post', '/users/' + args.id, null, null, args.data)
      .then( (response) => {
        commit('setUsers', response.data.data)
        return response
      })
      .catch( error => {
        commit('setUsers', error.data.data)
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },
  },

  mutations: {
    setUsers (state, args) {
      state.data = args
    }
  },

  getters: {
    users: state => state.data,
  }
}
