<template>
  <v-layout>
    <v-container fluid>
      <v-card class="pa-5">
        What are you doin here?
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: 'Test1'
}
</script>
