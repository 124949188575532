<template>
  <div class="text-center pt50">
    <v-snackbar
      v-model="loadingSnackbar.status"
      multi-line
      :color="loadingSnackbar.type ? loadingSnackbar.type : ''"
    >
      {{ loadingSnackbar.message }}
      <v-btn
        color="red"
        text
        @click="loadingSnackbar.status = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LoadingSnackbar',
    data() {
      return {
        snackbar: false,
      }
    },
    computed: mapState([
      'loadingSnackbar'
    ]),
}
</script>
