import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e40514',
        secondary: '#eda546',
        tertiary: '#23527c',
        accent: '#8DA7BE',
        error: '#FF5252',
        info: '#BDD5EA',
        success: '#32936F',
        warning: '#FFC107',
        anchor: '#23527c',
        low: '#4CAF50',
        medium: '#eda546',
        high: '#FF5252',
        duetoday: '#FFC0CB',
      },
    },
  },
})

export default vuetify
