<template>
  <v-layout>
    <v-container fluid>
      <v-form @submit.prevent="updateUser" ref="form">
        <v-card>
          <v-card-title>
            <h3>
              <v-icon class="mr-2" color="tertiary">mode_edit</v-icon>
              EDIT USER
            </h3>
            <v-spacer></v-spacer>
            <v-btn-toggle class="mr-5">
              <v-btn small to="/users/add">
                <v-icon small class="mr-1">person_add</v-icon>
                Add New User
              </v-btn>
              <v-btn small to="/users/list">
                <v-icon small class="mr-1">supervisor_account</v-icon>
                Users List
              </v-btn>
            </v-btn-toggle>
          </v-card-title>

          <v-divider />

          <v-card-subtitle>
            <h6 class="ml-6">last updated @ {{ users.data.updated_at }}</h6>
          </v-card-subtitle>

          <v-card-text class="pt-5">
            <v-layout wrap>
              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tl">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">contact_mail</v-icon>
                      USER DETAILS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="First Name"
                          prepend-inner-icon="perm_identity"
                          v-model="$v.newUser.firstName.$model"
                          :error="$v.newUser.firstName.$anyError"
                          :persistent-hint="$v.newUser.firstName.$invalid"
                          :error-messages="$v.newUser.firstName.$anyError ? 'First Name is required' : ''"
                          @blur="$v.newUser.firstName.$touch()"
                          :color="$v.newUser.firstName.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Last Name"
                          prepend-inner-icon="perm_identity"
                          v-model="$v.newUser.lastName.$model"
                          :error="$v.newUser.lastName.$anyError"
                          :persistent-hint="$v.newUser.lastName.$invalid"
                          :error-messages="$v.newUser.lastName.$anyError ? 'Last Name is required' : ''"
                          @blur="$v.newUser.lastName.$touch()"
                          :color="$v.newUser.lastName.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Email"
                          prepend-inner-icon="mail"
                          v-model="$v.newUser.email.$model"
                          :error="$v.newUser.email.$anyError"
                          :persistent-hint="$v.newUser.email.$invalid"
                          :error-messages="$v.newUser.email.$anyError ? 'A Valid Email is required' : ''"
                          @blur="$v.newUser.email.$touch()"
                          :color="$v.newUser.email.$anyError ? 'warning' : 'success'"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex md6 class="pa-3">
                <v-card flat tile>
                  <v-card-title class="grey lighten-4 rounded-tr">
                    <h5>
                      <v-icon class="mr-2" color="tertiary">lock</v-icon>
                      USER CREDENTIALS
                    </h5>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex md12 class="pa-2">
                        <v-text-field
                          label="Username"
                          prepend-inner-icon="lock"
                          v-model="$v.newUser.username.$model"
                          readonly
                          hint="Username cannot be edited"
                          persistent-hint
                          filled
                          disabled
                        />
                      </v-flex>

                      <v-flex md12>
                        <v-alert
                          color="info"
                          icon="info"
                        >
                          <H6><strong>THIS FEATURE IS IN THE WORKS</strong></H6>
                          To reset password, please go <a href="#">here</a>.
                          We will send an email and a url on where to change your password.
                        </v-alert>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

                <v-layout wrap v-if="!submitted">
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="text-center pa-5 d-block">
            <v-alert v-if="this.alert.status" :type="this.alert.type" :value="this.alert.status">
                {{ this.alert.message }}
            </v-alert>

            <v-btn type="submit" color="tertiary" dark class="pr-3" x-large>
              <v-icon class="mr-3">mode_edit</v-icon>
              Update User
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      </v-container>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
// import _ from 'lodash'

export default {
  name: 'UsersEdit',

  data () {
    return {
      show: false,
      showConfirm: false,
      newUser: {
        username: null,
        password: null,
        passwordConfirm: null,
        userLvl: 'superadmin',
        firstName: null,
        lastName: null,
        email: null,
        data: {}
      },
      submitted: false,
    }
  },

  validations: {
    newUser: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      username: false
    }
  },

  computed: mapState([
    'users',
    'alert'
  ]),

  mounted () {
    this.$store.dispatch('loadUser', this.$route.params.id)
  },

  watch: {
    users: {
      handler: function (newData) {
        if (!newData) return
        this.newUser = newData.data
      },
      deep: true
    }

  },

  methods: {
    updateUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.alert = { status: true, type: 'warning', message: 'Cannot Submit Form. Invalid Fields Detected.'}
      } else {
        this.$store.dispatch('updateUser', {
          id: this.$route.params.id,
          data: {
            userLvl: 'superadmin',
            firstName: this.newUser.firstName,
            lastName: this.newUser.lastName,
            email: this.newUser.email,
            data: this.newUser.data,
            status: 'active'
          }
        })
      }
    },
  }
}
</script>
