<template>
  <v-layout>
    <v-container fluid>
      <v-select
        v-model="country"
        :items="['USA','CAN','MEX']"
        hint="Number of Locations Per State"
        persistent-hint
        outlined
        class="ml-9"
        @change="emitCountry"
        solo
        rounded
      />
      <div id="statesChartWrap">
        <canvas id="statesChart" />
      </div>
    </v-container>
  </v-layout>
</template>

<script>
import Chart from 'chart.js'
import Colors from '@/constants/colors.js'

export default {
  name: 'States',

  data () {
    return {
      Colors: Colors,
    }
  },

  props: {
   data: Array,
   country: String
  },

  watch: {
    data: function(newVal, oldVal) {
      if(!newVal && !oldVal) {
        return
      } else {
        this.graph()
      }
    }
  },

  mounted () {
    this.graph()
  },

  methods: {
    goto (where) {
      this.$router.push({ path: '/team/' + where })
    },

    emitCountry () {
      this.$emit('countryListener', this.country)
    },

    graph () {
      if (!this.data || !this.country) return

      // var self = this
      // var title = 'Number of Locations per State in ' + this.country

      // clear canvas
      var itemNode = document.getElementById('statesChart')
      itemNode.parentNode.removeChild(itemNode)
      document.getElementById('statesChartWrap').innerHTML = '<canvas id="statesChart"></canvas>'

      // generate the chart
      var element = document.getElementById('statesChart')
      window.countryChart = new Chart(element, {
        type: 'bar',

        options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          },
          legend: {
              display: false
          },
          /*
          title: {
            display: true,
            text: title,
            position: 'top',
            fontSize: 20,
            fontColor: '#23527c',
            fontFamily: 'Montserrat'
          },
          */
        },

        data: {
          labels: this.data.map(function(d) {
            return d["state"]
          }),
          datasets: [
            {
              label: 'Number of Locations',
              data: this.data.map(function(d) {
                return d["count"]
              }),
              backgroundColor: this.Colors[12]
            },
          ]
        },
      })
    },
  }
}
</script>
