// ************************************************
// REF: https://flaviocopes.com/vuex/
// REF: https://appdividend.com/2018/05/08/vuex-axios-get-request-tutorial-with-example/
// ************************************************
import Vue from 'vue'
import Vuex from 'vuex'

import modules from '@/store/stores/index.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    alert:           {},
    editor:          {'status': false },
    loadingStatus:   {'status': false, 'message': null, 'color': 'success', 'persistent': true},
    user:            [],
    sheet:           {'status': false, 'message': null},
    loadingSnackbar: {},
    media:           {'status': false, 'data': null },
    loading:    false
  },

  actions: {
    clearLocalStorage (){
      // WE DONT NEED THIS>> CLEAN THIS UP
      // localStorage.removeItem('authenticated')
      // this.$store.commit('setAuthenticated', localStorage.getItem('authenticated'))
    },

    loadAlert ({ commit }, alert) {
      commit('setAlert', alert)
    },

    loadEditor ({ commit }, editor) {
      commit('setEditor', editor)
    },

    loadSheet ({ commit }, sheet) {
      commit('setSheet', sheet)
    },

    loadLoadingStatus ({ commit }, loadingStatus) {
      commit('setLoadingStatus', loadingStatus)
    },

    loadUser ({ commit }) {
      commit('setUser', localStorage.getItem('sflgaf_user'))
    },

    loadLoadingSnackbar ({ commit }, loadingSnackbar) {
      commit('setLoadingSnackbar', loadingSnackbar)
    },

    loadMedia ({ commit }, media) {
      commit('setMedia', media)
    },

    loadLoading ({ commit }, args) {
      commit('setLoading', args)
    }
  },

  mutations: {
    setAlert (state, newValue) {
      state.alert  = newValue
    },
    setEditor (state, newValue) {
      state.editor = newValue
    },
    setLoadingStatus (state, newValue) {
      state.loadingStatus = newValue
    },
    setUser (state, newValue) {
      state.user = JSON.parse(newValue)
    },
    setSheet (state, newValue) {
      state.sheet = newValue
    },
    setLoadingSnackbar (state, newValue) {
      state.loadingSnackbar = newValue
    },
    setMedia (state, newValue) {
      state.media = newValue
    },
    setLoading (state, newValue) {
      state.loading = newValue
    }
  },

  getters: {
    alert:           state => state.alert,
    editor:          state => state.editor,
    loadingStatus:   state => state.loadingStatus,
    sheet:           state => state.sheet,
    user:            state => state.user,
    loadingSnackbar: state => state.loadingSnackbar,
    media:           state => state.media,
    loading:         state => state.loading,
  },

  modules
})
