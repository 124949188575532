<template>
    <v-dialog
      v-model="confirm.data.status"
      max-width="320"
      persistent
    >
      <v-card>
        <v-card-title class="headline" id="confirmDialog">{{ confirm.data.message }}</v-card-title>
        <v-divider class="mb-5" />
        <v-card-text>
          {{ confirm.data.description }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            v-on:click="confirm.data.status = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red"
            text
            v-on:click="go(confirm.data)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
  
export default {
    name: 'Confirm',

    computed: mapState([
        //see store/index.js Obj.state
        'confirm',
        'ytlinks',
        'awards'
    ]),

  methods: {
    go (args) {

      this.ytlinks.data.splice(args.id, 1)
      this.awards.data.splice(args.id, 1)

      this.$emit('dialogConfirm', this.confirm)
      this.confirm.data.status = false
      // this.$store.dispatch('loadConfirm', args)

    }
  }  
}
</script>