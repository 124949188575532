<template>
  <div id="app">
    <v-app fluid>
      <sfl-loading-snackbar />
      <sfl-sidebar v-if="user != null && user.token" />
      <sfl-header v-if="user != null && user.token"  />
      <v-content>
        <sfl-alert  v-if="this.$router.currentRoute.name !== 'Login' && alert.status" />
        <v-container
          fluid
          fill-height
          id="maindiv"
          tag="div"
          class="pa-0"
        >

          <router-view id="main"></router-view>

          <sfl-footer v-if="user != null && user.token" />
          <sfl-loading-snackbar />
          <sfl-bottom-sheet />
          <sfl-loading-status />
          <sfl-confirm @dialogConfirm="dialogConfirm" />
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue'
import SideBar from '@/components/layouts/SideBar.vue'
import Footer from '@/components/layouts/Footer.vue'

import LoadingStatus from '@/components/partials/DialogLoadingStatus.vue'
import BottomSheet from '@/components/partials/DialogBottomSheet.vue'
import Confirm from '@/components/partials/DialogConfirm.vue'
import Alert from '@/components/partials/Alert.vue'
import LoadingSnackbar from '@/components/partials/DialogLoadingSnackbar.vue'

import { mapState } from 'vuex'

export default {
  name: 'App',

  components: {
    'sfl-header':           Header,
    'sfl-sidebar':          SideBar,
    'sfl-footer':           Footer,
    'sfl-loading-status':   LoadingStatus,
    'sfl-bottom-sheet':     BottomSheet,
    'sfl-alert':            Alert,
    'sfl-confirm':          Confirm,
    'sfl-loading-snackbar': LoadingSnackbar
  },

  created() {
    // do something
  },

  computed: mapState([
     //see store/index.js Obj.state
    'alert',
    'sheet',
    'loadingStatus',
    'confirm',
    'loadingSnackbar',
    'media',
    'user'
  ]),

  mounted () {
    this.$store.dispatch('loadUser') //see store/index.js
    //set bottom sheet to false | global
    this.$store.commit('setSheet', {'status': false, 'message': null})
    //set loader to false | global
    this.$store.dispatch('loadLoadingStatus', {'status': false, 'message': null})
    //set alert box to false | global
    this.$store.dispatch('loadAlert', {'status': false })
    //snackbar
    this.$store.dispatch('loadLoadingSnackbar', {'status': false, 'message': null})
    // check if logged in
    this.checkAuthentication('/')
  },

  methods: {
    checkAuthentication (to) {
      if ( this.$store.getters.user == null) {
        if(this.$router.app._route.name !== 'forgot') {
          this.$router.replace({ path: '/login' }).catch(err => { return err })
        }
      } else {
        this.$router.replace({ path: to.path }).catch(err => { return err })
      }
    },

    dialogConfirm () {
      this.$store.dispatch(this.$store.getters.confirm.callback)
    },
  },

  watch: {
    '$route' (to) {
      this.checkAuthentication(to)
      // clear alerts whenever you navigate on another page
      this.$store.commit('setAlert', {'status': false, 'message': '', 'type': ''})
    }
  }
}
</script>
