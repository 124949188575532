import Forgot from '@/components/pages/Forgot'

export default [
  {
    path: '/forgot-password',
    name: 'forgot',
    component: Forgot,
    meta: {
      icon: 'brush',
      sidebar: false,
      header: false,
      title: 'Forgot Password'
    },
  }
]
