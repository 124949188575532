<template>
  <v-footer app id="footer" inset color="#efeff7" class="justify-center">
      Copyright 2018-{{ new Date().getFullYear() }} Surefire Local | All Rights Reserved
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  data: () => ({
    // no data
  }),

  beforeRouteUpdate () {
    // console.log('beforeRouteUpdate')
  },

  watch: {
    // do something
  },

  mounted () {
    // do something
  },

}
</script>
