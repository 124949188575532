import E404 from '@/components/pages/E404'

export default [
	{
		path: '*',
		name: 'Page Not Found',
		component: E404,
    meta: {
      icon: 'block',
      sidebar: false,
      header: false,
      accessLvl: ['superadmin'],
      title: 'Error 404'
    },
	}
]
