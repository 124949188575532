import Login from '@/components/pages/Login'

export default [
	{
		path: '/login',
		name: 'login',
		component: Login,
    meta: {
      icon: 'brush',
      sidebar: false,
      header: false,
      title: 'Login to your Account'
    },
	}
]
