import axios from 'axios'
import { store } from '@/store/store'
import router from "@/router/router"

export const sfl_auth = (data) => {

  // BEFORE PROCESSING API CALL
  axios.interceptors.request.use(function (config) {
    // preloading message
    store.commit('setLoadingStatus', {
      'status': true,
      'message': 'Processing...',
      'color': 'tertiary',
      'persistent': true
    })
    return config;
    }, function (error) {
    store.commit('setLoadingStatus', {'status': true, 'message': 'Interceptor Request ' + error})
    return Promise.reject(error);
  })


  // AFTER PROCESSING API CALL
  axios.interceptors.response.use(function (response) {
    //remove preloader
    store.commit('setLoadingStatus', {'status': false, 'message': ''})
    return response;
  }, function (error) {
    store.commit('setLoadingStatus', {'status': false, 'message': null })
    store.commit('setAlert', {
      'status': true,
      'message': 'Invalid credentials',
      'type': 'error',
      'icon' : 'info'
    })
    return Promise.reject(error);
  })


  axios({
    method: 'post',
    responseType: 'application/json',
    url: process.env.VUE_APP_PLUGIN_API + '/login',
    data: {
      username: data.username,
      password: data.password
    }
  })
   .then(response => {

      switch(response.data.status) {
        case 200: // login success redirect to main page
          localStorage.setItem('sflgaf_user', JSON.stringify(response.data.data))
          store.commit('setUser', localStorage.getItem('sflgaf_user'))
          store.commit('setAlert', {'status': false, 'message': null })
          router.push({ path: '/' })
          break

        default: // show login error
          store.commit('setLoadingStatus', {'status': false, 'message': null })
          store.commit('setAlert', {
            'status': true,
            'message': response.data.description,
            'type': 'error',
            'icon' : 'info'
          })
          return Promise.reject();
      }

   })
   .catch( error => {
      localStorage.removeItem('sflgaf_user')
      localStorage.removeItem('sflgaf_locations')
      store.commit('setLoadingStatus', {'status': false, 'message': ''})
      return error
   })
}
