import ChangePassword from '@/components/pages/ChangePassword'

export default [
  {
    path: 'changepassword',
    name: 'changepassword',
    component: ChangePassword,
    meta: {
      icon: 'lock_open',
      sidebar: false,
      header: false,
      title: 'Update your password',
    },
  }
]
