import Users from '@/components/pages/users/Users'
import UsersAdd from '@/components/pages/users/UsersAdd'
import UsersList from '@/components/pages/users/UsersList'
import UsersEdit from '@/components/pages/users/UsersEdit'

export default [
    {
        path: '/users',
        name: 'users.main',
        component: Users,
        meta: {
          icon: 'people',
          sidebar: true,
          header: false,
          accessLvl: ['superadmin'],
          title: 'Users'
        },

        children: [
          {
            path: '/users/add',
            name: 'users.add',
            component: UsersAdd,
            meta: {
              icon: 'person_add',
              sidebar: true,
              header: false,
              title: 'Add User'
            },
          },
          {
            path: '/users/list',
            name: 'users.list',
            component: UsersList,
            meta: {
              icon: 'supervisor_account',
              sidebar: true,
              header: false,
              title: 'List Users'
            },
          },
          {
            path: '/users/:id',
            name: 'users.edit',
            component: UsersEdit,
            meta: {
              icon: 'home',
              sidebar: false,
              header: false,
              title: 'Edit User'
            },
          },
        ],
    }
]
