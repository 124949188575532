<template>
  <v-alert
    :type="alert.type ? alert.type : 'info'"
    v-model="alert.status"
    :icon="alert.icon"
    dismissible
    tile
    elevation="5"
  >
    {{ alert.message }}
  </v-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Alert',

    computed: mapState([
        //see store/index.js Obj.state
        'alert'
    ])
}
</script>
