import { sfl_api } from "@/helpers/sfl-api.js"
// import Locations from '@/temp/static-locations.json'

export const location  = {
  namespaced: false,
  state: {
    data: {
      location: [],
      data: {
        reviewLastUpdated: 'N/A',
        reviewLastTotalUpdate: 'N/A',
      },
      addLocation: false,
      errorLocation: false
    },
  },

  actions: {
    addLocation ({ commit }, args) {
      sfl_api('post', '/locations', null, null, args.data)
      .then(response => {
        if (response.data.data.length === 0) {
          commit('setErrorLocation', true)
        } else {
          commit('setErrorLocation', false)
          commit('setAddLocation', true)
        }
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    editLocation ({ dispatch, commit }, args) {
      sfl_api('post', '/locations/' + args.data.data.id, null, null, args.data.data)
      .then(response => {
        commit('setAlert', {
          'status': true,
          'type': 'success',
          'message': 'Location has been updated!',
          'persistent': false
        })
        dispatch('loadReviews', { id: args.data.data.id, page: 1, pagesize: 10, order: 'rating' })
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    loadLocation ({ dispatch, commit }, args) {
      sfl_api('get', '/locations/' + args, 'setLocation')
      .then(response => {
        dispatch('loadReviews', { id: response.data.data.gafId, page: 1, pagesize: 10 })
        return response.data
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
      })
    },

    getLocationReviews ({ dispatch, commit }, args) {
      dispatch('loadLoading', true)

      sfl_api('get', '/pullreviews/' + args.data.data.gafId, null, null, null)
      .then(response => {
        dispatch('loadLocation', args.data.data.id)
        dispatch('loadLoading', false)
        return response
      })
      .catch( error => {
        commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: Invalid GAFID',
          'persistent': false,
          'hideProgress': true,
          'color': 'red'
        })
        dispatch('loadLoading', false)
        return error
      })
    },
  },

  mutations: {
    setLocation (state, args) {
      state.data = args
    },

    setAddLocation (state, args) {
      state.data.addLocation = args
    },

    setErrorLocation (state, args) {
      state.data.errorLocation = args
    }
  },

  getters: {
    location: state => state.data,
    addLocation: state => state.data,
    errorLocation: state => state.data
  }
}
