import Vue from 'vue'
import Router from 'vue-router'
import pages from '@/router/routes/index.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash', // history mode causing bug on refresh must fix -- Japz
  base: '/',
  routes: pages,
})

export default router

router.beforeEach((to, from, next) => {
  if(to.name === null){
    next('/')
  }
  document.title = to.meta.title + ' :: SurefireLocal GAF'
  next()
})
