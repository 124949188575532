import Locations from '@/components/pages/locations/Locations'
import LocationsAdd from '@/components/pages/locations/LocationsAdd'
import LocationsList from '@/components/pages/locations/LocationsList'
import LocationsEdit from '@/components/pages/locations/LocationsEdit'

export default [
    {
        path: '/locations',
        name: 'locations.parent',
        component: Locations,
        meta: {
          accessLvl: ['superadmin'],
          icon: 'location_searching',
          sidebar: true,
          header: false,
          title: 'Locations'
        },

        children: [
          {
            path: '/locations/add',
            name: 'locations.add',
            component: LocationsAdd,
            meta: {
              accessLvl: ['superadmin'],
              icon: 'add_location',
              sidebar: true,
              header: false,
              title: 'Add Location'
            },
          },
          {
            path: '/locations/list',
            name: 'locations.list',
            component: LocationsList,
            meta: {
              accessLvl: ['superadmin'],
              icon: 'location_on',
              sidebar: true,
              header: false,
              title: 'List Locations'
            },
          },
          {
            path: '/locations/search/:keyword',
            name: 'locations.search',
            component: LocationsList,
            meta: {
              accessLvl: ['superadmin'],
              icon: 'location_on',
              sidebar: false,
              header: false,
              title: 'Search Locations'
            },
          },
          {
            path: '/locations/:locationID',
            name: 'locations.edit',
            component: LocationsEdit,
            meta: {
              accessLvl: ['superadmin'],
              icon: 'assignment',
              sidebar: false,
              header: false,
              title: 'Edit Location'
            },
          }
        ],
    }
]
