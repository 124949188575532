export const confirm  = {
  namespaced: false,
  state: {
    data: {'status': false, 'message': null, 'confirmed': false},
  },

  actions: {
    loadConfirm ({ commit }, args) {
      if (args !== "") {
        commit('setConfirm', args)
      }
    }
  },

  mutations: {
    setConfirm (state, args) {
      state.data = args
    },
  },

  getters: {
    confirm: state => state.data,
  }
}
