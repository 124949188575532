<template>
  <v-layout>
    <v-container fluid>
      <div id="countriesChartWrap">
        <canvas id="countriesChart" />
      </div>
      <div id="countryLegend"></div>
    </v-container>
  </v-layout>
</template>

<script>
// import { mapState } from 'vuex's
import Chart from 'chart.js'
import Colors from '@/constants/colors.js'

export default {
  name: 'Countries',

  data () {
    return {
      Colors: Colors,
    }
  },

  props: {
   data: Array,
  },

  watch: {
    data: function(newVal, oldVal) {
      if(!newVal && !oldVal) {
        // do something
      } else {
        this.graph()
      }
    }
  },

  created () {
    this.graph()
  },

  methods: {
    goto (where) {
      this.$router.push({ path: '/team/' + where })
    },

    showStates (args) {
      alert(args)
    },

    graph () {
      if (!this.data) return

      // var self = this
      var title = 'Number of Locations per Country'

      // clear canvas
      var itemNode = document.getElementById('countriesChart')
      itemNode.parentNode.removeChild(itemNode)
      document.getElementById('countriesChartWrap').innerHTML = '<canvas id="countriesChart"></canvas>'

      // generate the chart
      var element = document.getElementById('countriesChart')
      window.countryChart = new Chart(element, {
        type: 'doughnut',

        options: {
          weight: 50,
          cutoutPercentage: 25,
          legend: {
              display: true
          },
          legendCallback: function(chart) {
              var legendHtml = []
              legendHtml.push('<ul class="outer-donut">')
              var outer = chart.data.datasets[0];
              for (var o=0; o < outer.data.length; o++) {
                  legendHtml.push('<li style="border: 1px solid '+ outer.backgroundColor[o] +'">');
                  legendHtml.push('<span class="chart-legend" style="padding: 3px; text-align: center; background-color:' + outer.backgroundColor[o] +'">' + outer.labels[o] + ': ' +  outer.data[o].toLocaleString() + '</span>')
                  legendHtml.push('</li>')
              }

              legendHtml.push('</ul>')
              return legendHtml.join("")
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var index = tooltipItem.index;
                return dataset.labels[index] + ': ' + dataset.data[index];
              }
            }
          },
          title: {
            display: true,
            text: title,
            position: 'top',
            fontSize: 20,
            fontColor: '#23527c',
            fontFamily: 'Montserrat'
          },
        },

        data: {
          datasets: [
            {
              data: this.data.map(function(total) {
                return total["count"]
              }),
              backgroundColor: this.Colors,
              labels: this.data.map(function(label) {
                return label["country"]
              }),
              routes: this.data.map(function(route) {
                return route["country"]
              })
            },
          ]
        },
      })

      document.getElementById('countryLegend').innerHTML = window.countryChart.generateLegend()
    },
  }
}
</script>
