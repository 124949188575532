<template>
    <v-bottom-sheet 
        v-model="sheet.status"
        persistent
    >
        <v-sheet class="text-center" height="200px">
            <v-btn
                class="mt-6"
                color="warning"
                v-on:click="closeSheet()"
            >
                Got it!
            </v-btn>
            <div class="py-3">{{ sheet.message }}</div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex'
  
export default {
    name: 'BottomSheet',

    computed: mapState([
        //see store/index.js Obj.state
        'sheet'
    ]),    

    methods: {
        closeSheet () {
        this.$store.commit('setSheet', {'status': false, 'message': null})
        }
    }
}
</script>
