<template>
    <v-app-bar
      app
      color="white"
      flat
      clipped-left
      id="header"
      height="100"
      elevation="2"
    >
      <v-toolbar-title class="ml-5">
        <img
          width="auto"
          height="70px"
          src="@/assets/gaf-logo.png"
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div id="selectedLocation">
        <v-autocomplete
          v-model="selectedLocation"
          :loading="loading"
          :items="locationDropDown"
          :search-input.sync="headerSearch"
          hide-no-data
          item-text="locationName"
          item-value="id"
          label="Select Location"
          prepend-icon="search"
          placeholder="Start typing to search for location"
          outlined
          hide-details
          rounded
          @change="gotoLocation()"
          auto-select-first
          color="tertiary"
          validate-on-blur
        />
      </div>

      <v-spacer></v-spacer>

      <v-menu
        bottom
        close-on-click
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="transparent"
            v-bind="attrs"
            v-on="on"
            elevation="0"
            tag="a"
          >
            <span id="headerName">{{ user.firstName }} {{ user.lastName }}</span>
            <v-icon class="ml-2">keyboard_arrow_down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn block color="transparent" elevation="0" :to="'/users/' + user.id">
                <v-icon class="mr-2">account_box</v-icon>
                Profile
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn block color="transparent" v-on:click.native="logout()" id="logout" elevation="0">
                <v-icon class="mr-2">exit_to_app</v-icon>
                Logout
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-avatar size="72" class="ml-2" color="secondary">
        <span class="text-serif">{{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}</span>
      </v-avatar>
    </v-app-bar>
</template>

<script>
import router from '@/router/router'
import _ from 'lodash'
import { sfl_api } from "@/helpers/sfl-api.js"
import { sfl_auth_destroy } from "@/helpers/sfl-auth-destroy.js"
import { mapState } from 'vuex'
import debounce from 'debounce'

export default {
  name: 'Header',

    data: () => ({
      routes: _.filter(router.options.routes, {meta: { header: true}}),
      locationDropDown: [],
      headerSearch: '',
      selectedLocation: null,
      loading: false
    }),

  mounted () {
    this.$store.dispatch('loadUser')
  },

  computed: {

    ...mapState([
      'user',
      'locations'
    ])
  },

  watch: {
    headerSearch (value) {
      if (value && value.length > 2) {
        debounce(this.processSearch, 2000)(this.headerSearch)
      }
    }
  },

  methods: {
    gotoLocation () {
      if(this.selectedLocation) {
        this.$router.push('/locations/' + this.selectedLocation)

        if (this.$router.app._route.name === 'locations.edit') {
          this.$store.dispatch('loadLocation', this.selectedLocation)
        }
      }
    },

    processSearch (args) {
      this.loading = true
      sfl_api('get', '/locations?page=1&pagesize=20&search='+ args, null)
      .then(response => {
        this.selectedLocation = args
        this.locationDropDown = response.data.data.data
      })
      .then( () => {
        this.loading = false
      })
      .catch( error => {
        this.$store.commit('setLoadingStatus', {
          'status': true,
          'message': 'Error: ' + error,
          'persistent': false
        })
        this.loading = false
      })
      .finally(() => {
        // console.log(3, this.selectedLocation)
        // this.headerSearch = args
      })
    },

    logout () {
      sfl_auth_destroy()
    }
  }
}
</script>

<style>
/* all styles should be in sfs-ss/vue/src/css --Japz */
</style>
