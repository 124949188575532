<template>
  <v-layout>
    <v-container fluid>
    <v-card class="pa-5">
      <v-card-title>
        <h2 class="pa-1">
          <v-icon color="tertiary">cloud_upload</v-icon>
          Import Locations
        </h2>
      </v-card-title>

      <v-divider />

      <v-card-subtitle class="text-right">
        <v-btn href="./dist/static/img/sample.csv">
          <v-icon color="tertiary">download</v-icon>
          Download Sample CSV Here
        </v-btn>
      </v-card-subtitle>

      <v-card-text class="pa-5">
        <v-form>
          <v-btn icon>
            <v-icon>attach_file</v-icon>
          </v-btn>
          <input id="inputUpload" ref="inputUpload" type="file" >
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-5">
        <v-btn type="submit" color="tertiary" dark class="pl-6 pr-6" x-large v-on:click="uploadCSV()">
          <v-icon left>save</v-icon>
          Import
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="this.alert.status">
        <v-alert
          :color="this.alert.type"
          :icon="this.alert.icon"
          dark
        >
          {{ alert.message }}
        </v-alert>

        <v-alert
          v-if="this.result"
          border="top"
          color="tertiary"
          dark
          icon="check_circle"
        >
          Total Added Locations: {{ result.totalAdded }}
        </v-alert>

        <v-alert
          v-if="this.result && this.result.existing && this.result.existing.length > 0"
          border="top"
          color="warning"
          icon="warning"
        >
          Duplicate Locations Skipped: {{ result.existing.length }}
        </v-alert>
      </v-card-text>
    </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import { sfl_api } from "@/helpers/sfl-api.js"

    export default {
      name: 'Import',
      data () {
        return {
          result: null,
          media: [],
          alert: { status: false, type: null, message: null }
        }
      },

      mounted () {
        //
      },

      methods: {
        uploadCSV () {
            this.alert = { status: false, type: null, message: null }
            let formData = new FormData();
            //input file work around vs vuetify
            let csvurl = "";
            let csvfile = document.querySelector('#inputUpload');

            csvurl = csvfile.files[0];

            if(csvurl === undefined) {
              this.alert = {
                status: true,
                type: 'error',
                icon: 'warning',
                message: 'Error: No CSV File'
              }

              this.result = false
              return
            }

            formData.append("csv", csvurl)
            //iterate on all inputs and push it on formdata
            for (const [key, value] of Object.entries(this.media)) {
                formData.append(key, value)
            }

            sfl_api('post', '/uploadcsv', null, null, formData)
              .then( response => {
                  if(response.data.status === 200) {
                    this.alert = { status: true, type: 'success', icon: 'check', message: 'Import success'}
                    this.result = response.data.data
                  } else {
                    this.alert = {
                      status: true,
                      type: 'error',
                      icon: 'warning',
                      message: response.data.description
                    }
                    this.result = false
                  }

              })
        },
      }
    }
</script>
