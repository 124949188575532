import { store } from '@/store/store'
import router from "@/router/router"

export const sfl_auth_destroy = () => {
  // show loader message
  store.commit('setLoadingStatus', {'status': true, 'message': 'Logging out'})
  // clear data
  localStorage.removeItem('sflgaf_user')
  localStorage.removeItem('sflgaf_locations')

  // remove vuex data
  store.commit('setUser', null)
  // hide loader messages
  store.commit('setLoadingStatus', {'status': false, 'message': ''})
  // redirect to login
  router.replace({ path: '/login' })

  // store.replaceState({})

  // show confirm message
  store.commit('setAlert', {
    'status': true,
    'message': 'You have been logged out',
    'type': 'primary',
    'icon' : 'info'
  })
}
