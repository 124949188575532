<template>
  <v-dialog
      v-model="loadingStatus.status"
      hide-overlay
      :persistent="loadingStatus.persistent"
      width="300"
  >
      <v-card
        :color="loadingStatus.color"
        dark
        class="pa-3"
      >
          <v-card-text>
              {{ loadingStatus.message }}
              <v-progress-linear
                  v-if="!loadingStatus.hideProgress"
                  indeterminate
                  color="white"
                  class="mb-0"
              />
          </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'LoadingStatus',

    computed: mapState([
       //see store/index.js Obj.state
      'loadingStatus'
    ]),
}
</script>
