//***************************************************
// THIS FILE PROCESS ALL GET/POST/PUT/DELETE
// WHERE USING IS AS:
// sfl_api(
//  method   = get/post/put/delete
//  url      = api/endpoint url
//  mutation = data to mutate i.e. this.$store.commit('xxxx')
//  post_id  = for single post editing
//  data     = data to submit in post/put
// )
//***************************************************

import axios from 'axios'
import { store } from '@/store/store'
import { sfl_auth_destroy } from "@/helpers/sfl-auth-destroy.js"

export const sfl_api = (method, url, mutation, post_id = null, data = [], preloaderMessage = 'Processing...') => {
  // preloading message
  store.commit('setLoadingStatus', {
    'status': true,
    'message': preloaderMessage,
    'color': 'tertiary',
    'persistent': true
  })

  // BEFORE PROCESSING API CALL
  axios.interceptors.request.use(function (config) {
    return config;
    }, function (error) {
    sfl_auth_destroy('Interceptor request error', error, 'error')
    return Promise.reject(error);
  })

  // AFTER PROCESSING API CALL
  axios.interceptors.response.use(function (response) {
    //remove preloader
    store.commit('setLoadingStatus', {'status': false, 'message': ''})
    return response;
  }, function (error) {
    sfl_auth_destroy('Interceptor response error', error.response.data.error, 'error')
    return Promise.reject(error);
  })

  var urlArgs = (post_id !== null) ? '/' + post_id : '';

  // API CALL
  return axios({
    method: method,
    url: process.env.VUE_APP_PLUGIN_API + url + urlArgs,
    headers: { 'authtoken': JSON.parse(localStorage.getItem('sflgaf_user')).token },
    data: data
  })
  .then( response => {
    if(response.data.status == 200) {
      store.commit('setLoadingStatus', {
        'status': false,
        'message': null,
        'color': 'tertiary'
      })
      if (mutation !== null){
        store.commit(mutation, response.data.data)
      }
    } else {

        if(response.headers['content-disposition'].includes('attachment;')) {
          store.commit('setLoadingStatus', {
            'status': false,
            'message': null,
            'color': 'tertiary'
          })

          //download the file
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', response.headers['content-disposition'].split(';')[1] )
          document.body.appendChild(link)
          link.click()
        } else {
          store.commit('setLoadingStatus', {
            'status': true,
            'message': response.data.description,
            'persistent': false,
            'color': 'error'
          })
        }
    }

    Promise.resolve(response)
    return response
  })
  //if not logged in execute scripts below
  .catch( error => {
    sfl_auth_destroy('API error', error.response.data.error, 'error')
    return error
  })

}
