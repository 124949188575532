<template>
  <v-layout>
    <v-container fluid>
      <v-card rounded shaped>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :search="search"
            class="mb-5"
            mobile-breakpoint="768"
          >
            <template v-slot:top>
              <v-card-title>
                <h3>
                  <v-icon color="tertiary">format_list_numbered</v-icon>
                  USERS LIST
                </h3>
                <div class="flex-grow-1"></div>

                <v-btn-toggle class="mr-5">
                  <v-btn small to="/users/add">
                    <v-icon small class="mr-1">person_add</v-icon>
                    Add New User
                  </v-btn>
                </v-btn-toggle>

                <v-form>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hint="Search locations using id, name, city, state, zip and country as keyword"
                    persistent-hint
                    clearable
                  />
                </v-form>
              </v-card-title>
            </template>

            <template v-slot:item.email="{ item }">
              <router-link :to="{ name: 'users.edit', params: { id: item.id }}">
                {{ item.email }}
              </router-link>
            </template>

            <template v-slot:no-data>
                <tr>
                  <td>No data to show.</td>
                </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'UsersList',

  data () {
    return {
      search: '',
      itemsPerPage: [25, 50, 100, 250],
      tableData: [],
      headers: [
        { text: 'Email', value: 'email', width: '20%' },
        { text: 'Username', value: 'username', width: '20%' },
        { text: 'First Name', value: 'firstName', width: '20%' },
        { text: 'Last Name', value: 'lastName', width: '20%' },
        { text: 'Last Updated', value: 'updated_at', width: '20%' },
      ],
    }
  },

  computed: mapState([
    'users'
  ]),

  watch: {
    users: {
      handler: function (newData) {
        if (!newData) return
        this.tableData = newData.data
      },
      deep: true
    }
  },


  beforeRouteLeave  (to, from, next) {
    next()
    /*
    if(this.$router.app._route.name === "users.list") {
      this.$store.dispatch('loadUsers')
    }
    */
  },

  mounted () {
    this.$store.dispatch('loadUsers')
  },

  filter: {
    statusFilter (val) {
      return val
    }
  },

  methods: {
    goto (where) {
      // this.$router.push('/tag/' + this.$route.params.department + '/' + where )
      return where
    },
  }
}
</script>
