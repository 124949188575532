<template>
  <v-layout>
    <v-container fluid>
      <v-card rounded shaped>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            class="mb-5"
            mobile-breakpoint="768"
            dense
            :search="search"
          >
            <template v-slot:top>
				<h4 align="right">Created: {{ locations.data.created_at }}</h4>    
                <v-card-title>
                <h3>
                    <v-icon color="tertiary">compare</v-icon>
                    LOCATIONS DIFF
                </h3>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  label="Quick Search"
                  class="mx-4"
                  style="width: 200px"
                  clearable
                  hint="Search using Gaf ID or Name"
                />                
                </v-card-title>            
            </template>

            <template v-slot:item.oldValue="{ item }">
                <v-list-item two-line class="d-block">
                  <v-list-item-content v-for="(i, key) in Object.entries(item.oldValue).sort()" :key="key" class="pt-2 pb-2">
                    <v-list-item-title style="font-size: 10px;">{{ i[0].toUpperCase() }}</v-list-item-title>
                    <v-list-item-subtitle>{{ i[1] ? i[1] : 'NULL' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>                   
            </template>  

            <template v-slot:item.newValue="{ item }">
                <v-list-item two-line class="d-block">
                  <v-list-item-content v-for="(i, key) in Object.entries(item.newValue).sort()" :key="key" class="pt-2 pb-2">
                    <v-list-item-title style="font-size: 10px;">{{ i[0].toUpperCase() }}</v-list-item-title>
                    <v-list-item-subtitle>{{ i[1] ? i[1] : 'NULL' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> 
            </template>                         

            <template v-slot:no-data>
                <tr>
                  <td>No data to show.</td>
                </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'ImportDiff',

  data () {
    return {
      search: '',
      tableData: [],
      itemsPerPage: [25, 50, 100, 250],
      headers: [
        { text: 'GAF ID', value: 'gafId', width: '10%' },
        { text: 'Name', value: 'name', width: '20%' },
        { text: 'Old Value', value: 'oldValue', width: '35%' },
        { text: 'New Value', value: 'newValue', width: '35%' },
      ],
    }
  },

  computed: mapState([
    'locations'
  ]),

  watch: {
    locations: {
      handler: function (newData) {
        if (!newData.data.data) return
        // console.log( Object.entries(newData.data.data.updateRecords) )    
        this.tableData = newData.data.data.newRecords
      },
      deep: true
    },
  },

  beforeRouteLeave  (to, from, next) {
    next()
    // if(this.$router.app._route.name === "locations.list") {
    //   this.search = null
    //   this.$store.dispatch('loadLocations')
    // }
  },

  mounted () {
    this.$store.dispatch('diffLocations')
  },

  methods: {
    goto (where) {
      // this.$router.push('/tag/' + this.$route.params.department + '/' + where )
      return where
    },

    locationsPaginate (args) {
      if (this.$router.app._route.name === 'locations.search') {
        this.$store.dispatch('searchLocations', { pagesize: this.locations.data.per_page, keyword: this.$router.app._route.params.keyword, page: this.locations.data.current_page })
      } else {
        this.$store.dispatch('loadLocations', args)
      }
    }
  }
}
</script>
