import Test1 from '@/components/serve/Test1'

export default [
    {
        path: '/serve',
        name: 'Serve',
        component: Test1,
        meta: {
          icon: 'home',
          sidebar: false,
          header: false,
        },

        children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: 'test1',
            name: 'Test 1',
            component: Test1,
            meta: {
              icon: 'home',
              sidebar: false,
              header: false,
            },
          },
        ],
    }
]
